#ImgPageGrosOeuvre{
    background-image: url('../assets/img/pagegrosoeuvre.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#ImgPageGrosOeuvre2{
    background-image: url('../assets/img/pagegrosoeuvre2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#ImgPageGrosOeuvre3{
    background-image: url('../assets/img/pagegrosoeuvre3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#ImgPageTravauxServices{
    background-image: url('../assets/img/imgtravauxServices.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 25rem;
    width: auto;
}

#ImgPageconstbat{
    background-image: url('../assets/img/pageconstbat.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 25rem;
    height: 30rem;
}

#ImgPageconstbat2{
    background-image: url('../assets/img/pageconstbat2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 25rem;
    height: 30rem;
}

#ImgPageGenieCivil{
    background-image: url('../assets/img/pagegeniecivil.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#ImgPageBureauEtude{
    background-image: url('../assets/img/pagebureauetude.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#ImgPageMissionControle{
    background-image: url('../assets/img/pagemissioncontrole.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media (max-width: 768px) {
    #ImgPageconstbat {
        width: 15rem;
        height: 20rem;
    }
    #ImgPageconstbat2 {
        width: 15rem;
        height: 20rem;
    }
}

@media (max-width: 426px) { 
    #ImgPageconstbat {
        display: none;
    }
    #ImgPageconstbat2 {
        display: none;
    }
}


.textAlign{
    text-align: justify;
}

.borderStyle{
    border-bottom: solid 2px var(--orange);
}