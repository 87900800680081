@font-face {
    font-family: "livingst";
    src: url("../assets/font/Livingst.ttf");
}

.borderShadowProfile
{
    box-shadow: 10px 5px 5px black;
}
.profilTitle
{
    font-weight: bold;
    font-size: 2.5rem;
    font-family: "livingst";
}

.textStyle{
    font-family: "livingst";
    font-size: 1.7rem;
}

#heroProfileEnfant {
    /* backdrop-filter: brightness(55%) blur(1rem); */
    width: 100%;
    height: 100%;
    min-height: 100vh;
}


.padding {
    padding: 3rem !important
}

.user-card-full {
    overflow: hidden
}

#cardProfil {
    box-shadow: 10px 5px 5px #0006;
    border-radius: 5%;
    border: none;
    max-height: 91%;
}

@media (min-width: 1200px) and (max-width: 6000px) { 
    #cardProfil {
    min-width: 500px;
    max-width: 500px;
    width: 500px;
    }
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px
}

#cardGeneral{
    padding: 4em;
    box-shadow: 10px 5px 5px #0006;
    -webkit-box-shadow: 10px 5px 5px #0006;
    border-radius: 5%; 
}

.bg-c-lite-green {
    /* background: -webkit-gradient(linear, left top, right top, from(#f29263), to(#ee5a6f));
    background: linear-gradient(to right, #ee5a6f, #f29263); */
    background-color: var(--orange);
}

.user-profile {
    padding: 20px 0
}

.card-block {
    padding: 1.25rem
}


.img-radius {
    border-radius: 5px
}

h6 {
    font-size: 14px
}

.card .card-block p {
    line-height: 25px
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}

.card-block {
    padding: 1.25rem
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}


.card .card-block p {
    line-height: 25px
}


.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}


.user-card-full .social-link li {
    display: inline-block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}


@media (max-width: 571px) {
    #cardProfil {
        max-height: 100%;
    }
}