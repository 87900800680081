
.chart-container-pies {
    width: 50%;
    margin: 20px auto;
}
@media screen and (max-width: 870px) {
    .chart-container-pies {
        width: 100%;
        margin: 20px auto;
    }
}


.global-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.global-container h2 {
    font-size: clamp(20px, 2vw, 30px);
    text-align: center;
    margin: 30px 0 20px;
    text-transform: uppercase;
    font-weight: 400;
}

.global-container h2:not(.global-container h2:nth-child(1)){
    margin-top: 70px;
}



.containerWhite {
    margin-top: 7em!important;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 5px 5px rgba(0,0,0,0.43); 
    box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 5px 5px rgba(0,0,0,0.43);
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
}






.h1v3 {
    font-size: clamp(20px, 3vw, 40px);
    text-align: center;
    margin: 30px 0 20px;
    text-transform: uppercase;
    font-family: Open sans;
}

.global-container form {
    margin: 0 auto;
    max-width: 400px;
}
.global-container label {
    display: block;
    text-align: center;
    font-size: clamp(16px, 2vw, 30px);
}
.global-container select {
    padding: 10px 8px;
    display: block;
    font-size: 20px;
    margin: 10px auto 50px;
}

.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.chart-container {
    position: relative; 
    width: 55%;
    margin: 20px;
}

@media screen and (max-width: 900px) {
    .chart-container {
        width: 90%;
        margin: 10px;
    }

    .none-sm {
        display: none;
    }
}