:root {
    --orange: #D48E19;
    --blanc: #FFFFFF;
    --gris: #8898AA;
    --noir: #000000;
}


@font-face {
    font-family: "livingst";
    src: url("../assets/font/Lora.ttf");
}

@font-face {
    font-family: "CELTICHD";
    src: url("../assets/font/Lora.ttf");
}

nav {
    font-family: "livingst";
    background-color: #000000e0;
    position: fixed;
    z-index: 99;
    top: 0;
}

.dropdown-menu {
    font-family: "livingst";
    background-color: #000000e0;
    font-size: 0.8em;
}

.dropdown-menu a {
    text-decoration: none;
    font-size: 1em;
}

.dropdown-menu a:hover {
    background-color: #000000e0;
    color: var(--orange);
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--gris);
  }

#nav-dropdown {
    font-size: 1.4em;
}

.navbar-expand-lg {
    position: fixed !important;
    padding-bottom: 2.5em;
    padding-top: 2.5em;
}

#logoNav {
    height: 3em;
    width: auto;
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255) !important;
    font-size: 1.5em;
}

.navbar-light .navbar-toggler {
    background: white;
}

.nav-link a {
    text-decoration:none; 
}
nav .nav-link a:hover {
    /* text-decoration: underline; */
    color: var(--orange);
}

nav .nav-link a:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0;
    background: transparent;
    transition: width .4s ease, background-color .5s ease;
    position: relative;
    top: -15px;
  }
  nav .nav-link a:hover:after {
    width: 90%;
    background: var(--orange);
  }

#navv {
    transition: all 0.5s ease;
}

.navbarScroll {
    transition: all 0.5s ease;
    top: -90px;
}



nav dropdown-item:hover {
    /* text-decoration: underline; */
    color: var(--orange);
}

nav .dropdown-item:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0;
    background: transparent;
    transition: width .4s ease, background-color .5s ease;
    position: relative;
    top: -15px;
  }
  nav .dropdown-item:hover:after {
    width: 90%;
    background: var(--orange);
  }

@media screen and (min-width: 991px) and (max-width: 1225px) {
    nav a {
        font-size: 0.8em;
    }

    #nav-dropdown {
        font-size: 1.3em;
    }
}
@media (max-width: 990px) {

    .navbar-collapse {
        background: var(--gris);
    }

    #navbarScroll {
        text-align: center;
    }

    .navbar {
        padding-top: -0.5rem;
    }

    .navbar-expand-lg {
        padding-bottom: 0em;
        padding-top: 0em;
    }
    nav .nav-link a:hover:after {
        width: 10%;
      }
}

#colRightValeur {
    background-image: url('../assets/img/5193671.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.firstSection {
    background-image: linear-gradient(90deg, rgba(79, 79, 92, 0.5383287103904062) 0%, rgba(59, 64, 65, 0.4991130241158963) 100%),
        url('../assets/img/5129415.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.firstSection .title h1 {
    font-size: 2.6em;
    font-family: "CELTICHD";
}

.firstSection .title h2 {
    font-size: 1.5em;
}

.firstSection .title {
    color: var(--blanc);
}

.sdSection {
    background: var(--blanc);
    height: auto;
    font-family: "helvetica";
    text-align: justify;
}

#styleText {
    padding-right: 2em;
}

.contentSdSection {
    color: var(--noir);
}

.contentSdSection h2 {
    border-bottom: solid 2px var(--orange);
    font-size: 3.4em;
    margin-bottom: 1em;
    margin-top: 1em;
    font-family: "CELTICHD";
}

.contentSdSection h3 {
    font-family: "CELTICHD";
}

.textJustify {
    width: 50%;
}

.contentSdSection p {
    margin-bottom: 1em;
}

.sdSection a {
    background-color: var(--noir);
    color: var(--blanc);
}

.sdSection a:hover {
    background-color: var(--blanc);
    color: var(--noir);
    transition: all ease-out 0.6s;
}

.valueSize {
    font-size: 2em;
}

#imgValues {
    height: 12em;
    width: auto;
}

.threeSection {
    height: auto;
    min-height: 70vh;
    background-color: var(--gris);
    font-family: "helvetica";
}

.threeSection p {
    text-align: justify;
}

.fourSection {
    height: 100vh;
}

.sectionImgHome {
    background: rgb(79, 85, 92);
    background: linear-gradient(90deg, rgba(79, 85, 92, 1) 32%, rgba(16, 3, 3, 0.8828665255164566) 84%),
        url('../assets/img/jesse-orrico-L94dWXNKwrY-unsplash.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
.numberDiv {
    color: var(--blanc);
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divGalleryMobile {
    display: none;
}

.divGallery {
    display: flex;
}

.img-thumbnail {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    z-index: 2;
}

.div_img:hover .img-thumbnail {
    -webkit-filter: brightness(45%);
    filter: brightness(45%);
    transform: scale(1.3);
    z-index: 5;
    transform: scale3d(1.1, 1.1, 1);
}


#linkUpdate {
    text-decoration: none;
    color: inherit;
}

footer {
    background: var(--orange) !important;
}

h4 {
    font-size: 2.3em!important;
}

@media (max-width: 1400px) {
    #imgValues {
        height: 10em;
        width: auto;
    }
}

@media (max-width: 990px) {
    .threeSection {
        height: auto;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .sdSection {
        padding-bottom: 3em;
        height: auto;
    }

    .divGallery {
        display: none;
    }

    .divGalleryMobile {
        display: flex;
    }

    #imgValues {
        height: 7em;
        width: auto;
    }

    .sectionImgHome {
        height: 55vh;
    }

    .firstSection {
        height: 75vh;
    }

    .firstSection .title h2 {
        font-size: 1.2em;
    }
}

@media (max-width: 768px) {
    #imgValues {
        display: none;
    }

    #styleText {
        width: 100%;
    }

    .firstSection .title h1 {
        text-align: center;
    }
}

@media (max-width: 575px) {

    .divGalleryMobile {
        flex-direction: column;
    }

    #h-100 {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .contentSdSection h3 {
        font-size: 1.8em;
    }

    .auth.auth-img-bg .auth-form-transparent {
        width: 80%;
        margin: auto;
    }
}

#logoFooter {
    height: 6.5em;
    width: auto;
}

#imgRounded {
    height: 10em;
    width: 10em;
}


.Toastify__toast-container--top-center {
    top: 6em!important;
}

.planningChantier{
    width: 100%;
}

.from {
    width: 70%;
}

form {
    width: 90%;
}