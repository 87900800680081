@font-face {
    font-family: "livingst";
    src: url("../assets/font/Lora.ttf");
}

#formEstimate {
    width: 100%!important;
}
.estimateStyleTitle{
    font-family: "livingst";
    font-size: 3rem;
    color: white;
}

.textStyle{
    font-family: "livingst";
    font-size: 1.5rem;
}

.btnStyle{
    font-family: "livingst";
    font-size: 2rem;
    line-height: 0;
    background-color: #D48E19 !important;
}

.styleArea{
    height: 10rem;
}

.heroEstimate {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(90deg, rgba(79, 79, 92, 0.5383287103904062) 0%, rgba(59, 64, 65, 0.4991130241158963) 100%),
    url('../assets/img/image2_filter.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 5em;
}
.stickyDope{
    position: fixed!important;
}