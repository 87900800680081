.heroMaterial {
    /* min-height: 100vh;
    height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image: linear-gradient(90deg, rgba(79, 79, 92, 0.5383287103904062) 0%, rgba(59, 64, 65, 0.4991130241158963) 100%),
    url('../assets/img/image2.jpg'); */
    background-image: linear-gradient(90deg, rgba(79, 79, 92, 0.5383287103904062) 0%, rgba(59, 64, 65, 0.4991130241158963) 100%),
    url('../assets/img/image2_filter.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 5em;
}


#materialFristDiv {
    /* backdrop-filter: brightness(55%) blur(1rem);*/
    width: 100%;
    height: 100%; 
}




#container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 1.5em;
padding-right: 1.5em;
}

#container .card {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 1em;
    margin-right: 1em;
    box-shadow: 4px 4px #00000054;
}

#tableMaterialDetail {
    margin-top: 7em;
}


@media (max-width: 990px) {
    .heroMaterial {
        padding: 0em;
    }
}


.heroProblemeEnfant {
    /* backdrop-filter: brightness(55%) blur(1rem); */
    width: 100%;
    height: 100%;
    min-height: 100vh;
}